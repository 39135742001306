import React from 'react'
import './Join.css'
import emailjs from 'emailjs-com' 
import { useRef } from 'react'
const Join = () => {
    const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_lxmqo7e', 'template_u31g57k', form.current, 'RJ3hmMdRusqwLbn6W')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });

      e.target.reset();
  };
  return (
    <div className="Join" id="join-us">
        <div className="left-j">
            <hr />
            <div>
                <span className='stroke-text'>READY TO</span>
                <span>LEVEL UP</span>
            </div>
            <div>
                <span>YOUR BODY</span>
                <span className='stroke-text'>WITH US?</span>
            </div>
        </div>
        <div className="right-j">
            <form action="" className="email-container" ref={form} onSubmit={sendEmail}>
                <input type="email" name='email' placeholder='Enter Email address'/>
                <button className='btn btn-join'>Join Us</button>
            </form>
        </div>
    </div>
  )
}

export default Join
